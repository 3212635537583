$colorRed: #c41230;
$colorDisabled: #95757b;
$colorWhite: #fff;
$colorBlack: #000;
$colorGrey: #f0f0f0;
$colorGreyFont: #555555;
$colorGreyFont2: #777777;
$colorGreyFont3: #333333;
$colorGreyBorder: #d9d9d9;
$colorGreyBorder2: #c4c4c4;

$ftMontserrat: "Montserrat", sans-serif;
$fz13: 0.8125rem;
$fz15: 0.9375rem;
$fz18: 1.125rem;
$boxShadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
$boxShadowModal: 0px 19px 38px rgba(0, 0, 0, 0.3),
  0px 15px 12px rgba(0, 0, 0, 0.22);
$colorGreyBg: #949599;
