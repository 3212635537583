


.is-preload .preloader-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.5);
}

.is-preload .preloader {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  border: 5px solid #d05048;
  border-radius: 50%;
  border-color: #d05048 transparent #d05048 transparent;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  visibility: visible;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
