main > div {
  position: relative;
  height: 100%;
}
.main-index-container {
  overflow: hidden;
}
.main-container {
  height: 100%;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  background: $colorWhite;
}
.top-promo {
  height: 29%;
  position: relative;
  min-height: 140px;
  transition: opacity 0.5s;
  opacity: 1;
  &-holder {
    background: $colorRed;
    height: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    transition: opacity 0.5s;
    opacity: 1;
  }
}
.map {
  &-btn {
    padding: 0 0 9px;
    position: relative;
    z-index: 1;
    cursor: pointer;
    &-box {
      position: absolute;
      bottom: 0;
      margin: 0 0 -13px;
      padding: 0 0 16px;
      left: 50%;
      transform: translate(-50%, 0);
      color: #fff;
      font-weight: 500;
      font-size: 0.94em;
      cursor: pointer;
      z-index: 1;
    }
    .icon {
      background: url("../images/icon-location-point.svg") no-repeat;
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 14px;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
    }
    &-close {
      background: none;
      border: 0;
      padding: 10px 0;
      &-holder {
        text-align: center;
        line-height: 0;
        padding: 6px 0 0;
      }
      &:before {
        content: "";
        width: 29px;
        height: 7px;
        display: block;
        background: url("../images/ic-map-close.svg");
      }
    }
  }
  &-icon-arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 43px;
    height: 13px;
    background: $colorRed;
    transform: translate(-50%, 0);
    cursor: pointer;
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 13px 0;
      border-color: transparent $colorRed transparent transparent;
      position: absolute;
      left: -10px;
      top: 0;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 13px 10px 0 0;
      border-color: $colorRed transparent transparent transparent;
      position: absolute;
      right: -10px;
      top: 0;
    }
    .icon-map-arrow {
      background: url("../images/icons/icon-arrow-map.svg") no-repeat 50% 50%;
      width: 60px;
      height: 22px;
      position: absolute;
      top: -11px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  &-section {
    height: 29%;
    overflow: hidden;
    transition: height 0.8s;
    background: $colorGrey;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    .city {
      &-info {
        font: 16px/20px "Montserrat", sans-serif;
      }
      &-name {
        font-weight: bold;
        margin: 0 0 9px;
        font-size: 1rem;
      }
      &-phone,
      &-email,
      &-location {
        margin: 0 0 10px;
        font-size: 0.94em;
        line-height: 1.2;
        font-weight: 500;
      }
    }
  }
  &-info-window {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $colorWhite;
    padding: 15px 20px 0;
  }
}
.isMap {
  .top-promo {
    height: auto;
    position: static;
    min-height: 0;
    //position: absolute;
    //z-index: 3;
    &-holder {
      opacity: 0;
      height: 0;
      transition: opacity 0.5s;
    }
  }
  .map {
    &-section {
      height: 100%;
      opacity: 1;
      pointer-events: auto;
      position: static;
    }
    &-btn {
      &-box {
        background: $colorWhite;
        bottom: 0;
        margin: 0 0 16px;
        width: 47px;
        height: 47px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        transition: bottom 1s;
      }
    }
    &-icon-arrow {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: none;
      height: 100%;
      width: 100%;
      background: none;
      &:after,
      &:before {
        display: none;
      }
      .icon-map-arrow {
        top: 0;
        width: 100%;
        height: 100%;
        background: none;
        &:before,
        &:after {
          content: "";
          width: 29px;
          height: 3px;
          background: $colorRed;
          position: absolute;
          left: 50%;
          top: 50%;
          transform-origin: 50% 50%;
          transform: translate(-50%, -50%);
        }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
  .phone-btn {
    left: 10px;
  }
}
.main-menu-section {
  display: flex;
  flex-wrap: wrap;
  height: calc(71% - 55px);
  padding: 45px 28px 18px;
  min-height: 250px;
  a {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    line-height: 1.3;
    position: relative;
    cursor: pointer;
    min-height: 110px;
    &:nth-child(n + 3) {
      border-top: 2px solid $colorGrey;
    }
    &:nth-child(2n) {
      border-left: 2px solid $colorGrey;
      padding-left: 28px;
    }
    &:nth-child(2n + 1) {
      padding-right: 28px;
    }
  }
  .icon {
    display: block;
    text-indent: -9999px;
    width: 95px;
    height: 95px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .text {
    white-space: pre-line;
  }
}
.icon {
  &-catalog {
    background-image: url("../images/icons/ic-catalog-new.svg");
  }
  &-spare-part {
    background-image: url("../images/icons/ic-spare-part-new.svg");
  }
  &-order-services {
    background-image: url("../images/icons/ic-order-services-new.svg");
  }
  &-teaching {
    background-image: url("../images/icons/ic-teaching-new.svg");
  }
  &-telegram {
    &:after {
      background-image: url("../images/socials/icon-telegram.svg");
      width: 33px;
      height: 33px;
    }
  }
  &-instagram {
    &:after {
      background-image: url("../images/socials/instagram_icon.svg");
      width: 30px;
      height: 31px;
    }
  }
  &-messenger {
    &:after {
      background-image: url("../images/socials/icon-messenger.svg");
      width: 35px;
      height: 35px;
    }
  }
  &-whatsup {
    &:after {
      background-image: url("../images/socials/icon-whatsup.svg");
      width: 36px;
      height: 36px;
    }
  }
}
.logo {
  margin: 0 0 14px;
  &-text {
    color: $colorWhite;
    font-size: 0.94em;
    line-height: 1.2;
    font-weight: 600;
    padding: 0 40px;
  }
}
.information {
  &-btn {
    color: $colorRed;
    cursor: pointer;
    font-size: 0.94em;
    line-height: 1.27;
    &-box {
      width: 100%;
      text-align: center;
      padding: 13px 0 13px;
      position: relative;
      z-index: 10;
    }
    .icon {
      background: url("../images/ic-arrow-information.svg") no-repeat;
      width: 26px;
      height: 10px;
      display: block;
      margin: 0 auto;
      transition: all 0.3s;
    }
    &.showedInfo {
      .icon {
        transform: scaleY(-1);
      }
    }
  }
  &-section {
    padding: 0 28px;
    width: 100%;
    transition: all 0.3s;
  }
  &-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7px 0 5px;
    border-bottom: 2px solid $colorGrey;
  }
  &-item {
    color: $colorBlack;
    font-size: 1em;
    line-height: 1.25;
    margin: 0 0 15px;
    font-weight: 600;
  }
}
.phone {
  &-section {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    transition: all 0.3s;
    z-index: 1;
    &.opened {
      transform: translateX(0);
    }
  }
  &-btn {
    width: 54px;
    height: 52px;
    background: $colorBlack;
    border-radius: 50% 0 0 50%;
    position: absolute;
    top: 25%;
    left: -54px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    line-height: 54px;
    color: $colorWhite;
    text-indent: -9999px;
    transition: 0.8s left;
    &:after {
      content: "";
      background: url("../images/icon-phone.svg") no-repeat 50% 50%;
      position: absolute;
      left: 3px;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  &-panel {
    width: 270px;
    background: $colorBlack;
    height: 100%;
    color: $colorWhite;
    padding: 30px;
    &-items {
      overflow: hidden;
      margin-bottom: 6px;
    }
    &-item {
      margin: 0 0 40px;
    }
    &-title {
      font-size: 1.13em;
      line-height: 1.22;
      margin: 0 0 10px;
    }
    &-value {
      font-weight: bold;
      font-size: 1.25em;
      line-height: 1.2;
    }
    &-socials {
      display: flex;
      flex-wrap: wrap;
      margin-right: -22px;
      &-item {
        margin-right: 22px;
      }
      a {
        i {
          display: block;
          width: 36px;
          height: 36px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  a {
    color: $colorBlack;
    font-size: 0.88em;
    line-height: 1.21;
    margin: 0 0 10px;
  }
}
.map-info-window-slide {
  &-leave-active,
  &-enter-active {
    transition: 0.5s;
  }
  &-enter {
    transform: translate(0, -100%);
  }
  &-leave-to {
    transform: translate(0, -100%);
  }
}
