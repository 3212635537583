

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modal-body p {
  font-size: 0.9375rem;
  margin: 0 0 15px;
}
.modal-body .rating-stars {
  padding-right: 0;
}
#modal-rating h3 {
  margin: 0;
}
.modal-rating-thx .modal-title {
  margin-bottom: 0;
}
