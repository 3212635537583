

.login-buttons {
}
.vue-otp-input {
  width: 100%;
  margin: 0 auto;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
  text-align: left;
}

.vue-otp-input.error {
  position: relative !important;
  bottom: 0px !important;
}
.btn-full-width {
  cursor: pointer;
}
.helper-text {
  margin-bottom: 30px;
}
.helper-text span {
  font-weight: 700;
}
.forgot-button {
  margin: 0 0 20px;
}
.btn-border {
  border: 1px solid #c70629;
  padding: 16px 10px;
  background: #fff;
  color: #000;
  display: block;
  font-weight: bold;
}
.link-center {
  text-align: center;
  display: block;
  background: transparent;
  border: none;
  margin-top: 10px;
}

.email-link {
  margin-bottom: 20px;
}

.section-main-form {
  /*padding: 50% 0 0px;*/
  padding: 55px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}
.button-row {
  margin: 0 0 15px;
}
.error-message {
  margin: -20px 0 35px;
  font-size: 13px;
  line-height: 16px;
  color: #c70629;
}
.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.form-group label {
  left: 15px;
}
.form-group .error {
  position: relative;
}
