
.paddingSpan {
  display: block;
  padding: 10px;
  &:before {
    content: '— ';
    color: #c70629;
  }
}

.zagolovok {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  &:before {
    content: ''
  }
}

.contentText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 146.28%;
  margin-top: 20px;
}
.img {
  padding: 20px 0;
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
}
