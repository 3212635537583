

.btn-holder .btn.btn-link-red {
  margin-right: 20px;
}
.page-container {
  height: auto;
}

.service-ticket__text-select {
  position: relative;
}

.service-ticket__text-select::after {
  content: "";
  width: 12px;
  height: 10px;
  z-index: 100;
  position: absolute;
  right: 18px;
  top: 18px;
  background: url("../../images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.service-ticket__text-select--open::after {
  content: "";
  width: 12px;
  height: 10px;
  z-index: 100;
  position: absolute;
  right: 18px;
  top: 18px;
  background: url("../../images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(180deg);
  animation-name: arrow-rotate;
  animation-duration: 500ms;
}

@keyframes arrow-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
