.catalog {
  &-main-section {
    padding-bottom: 0;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    a {
      width: 50%;
      height: 50vw;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:nth-child(2n) {
        border-left: 2px solid $colorGrey;
      }
      &:nth-child(n+3) {
        border-top: 2px solid $colorGrey;
      }
      &:nth-last-child(2):nth-child(2n) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          border-bottom: 2px solid $colorGrey;
        }
      }
      &:nth-last-child(1):nth-child(2n+1) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: -2px;
          height: 100%;
          border-right: 2px solid $colorGrey;
        }
      }
    }
  }
  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    margin: 0 0 20px;
    overflow: hidden;
  }
  &-item-name {
    display: block;
    font-size: 1em;
    line-height: 1.25;
    font-weight: 600;
    word-break: break-word;
  }
  &-detail {
    .section-top {
      background: transparent;
      border-bottom-color: transparent;
    }
    .section-top {
      width: 40px;
      z-index: 10;
    }
    .link-back {

    }
  }
}
.sub-catalog {
  &-list {
    .info-list {
      overflow: hidden;
      &__row {
        margin: 0 0 10px;
        display: flex;
        color: $colorGreyFont2;
        font-size: $fz15;
        line-height: 1.2;
      }
      &__title {
        padding: 0 7px 0 0;
      }
      &__value {
        font-weight: 600;
      }
    }
  }
  &-item {
    &__img {
      overflow: hidden;
      a {
        display: block;
      }
      img {
        width: 100%;
      }
    }
    &__info {
      padding: 20px 20px 40px;
    }
    &__name {
      font-size: $fz18;
      line-height: 1.23;
      font-weight: bold;
      margin: 0 0 10px;
    }
  }
}
.detail {
  &-image {
    text-align: center;
    position: relative;
    margin: -55px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 309px;
    overflow: hidden;
    &-frame {
      position: relative;
    }
  }
  &-points {

  }
  &-point {
    position: absolute;
    width: 27px;
    height: 27px;
    border: 1px solid $colorRed;
    border-radius: 50%;
    background: $colorWhite;
    box-shadow: $boxShadow;
    cursor: pointer;
    &:before {
      content: '';
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $colorRed;
      position: absolute;
      top: 6px;
      left: 6px;
    }
    &.active {
      border-color: $colorBlack;
      &:before {
        background: $colorBlack;
      }
    }
  }
  &-info {
    padding: 0 20px;
    &__container {
      padding: 0 0 40px;
    }
    &__item {
      &:nth-child(n+2) {
        padding: 20px 0 0;
        margin: 20px 0 0;
        border-top: 1px dashed rgba(0,0,0,.15);
      }
      &.change-info-box {
        &+.detail-info__item {
          border: 0;
          padding: 0;
        }
      }
    }
    &__title {
      margin: 0 0 5px;
      color: $colorGreyFont;
      line-height: 1.25;
    }
    &__value {
      font-weight: 600;
    }
    &__parameters {
      display: flex;
      flex-wrap: wrap;
      padding: 6px 0 0 1px;
    }
    &__parameter {
      border: 1px solid $colorGreyBorder;
      margin: -1px 0 0 -1px;
      width: calc(25% + 1px);
      line-height: 1.25;
      color: $colorRed;
      text-align: center;
      padding: 13px 0;
      white-space: pre-line;
      &.active {
        color: $colorBlack;
        position: relative;
        border-color: $colorRed;
      }
    }
  }
  &-title {
    font-size: $fz18;
    line-height: 1.225;
    font-weight: bold;
    margin: 0 0 20px;
  }
  &-description {
    padding: 0 20px;
    height: calc(100vh - 309px - 20px);
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: -48px;
    &-text {
      padding: 0 0 30px;
      p {
        margin: 0;
      }
    }
  }
}
.activePointDetail {
  .icon-arrow-back {
    background-image: url('../images/ic-close-black.svg');
  }
}