

.login-buttons {
}
.forgot-button {
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.btn-border {
  border: 1px solid #c70629;
  padding: 16px 10px;
  background: #fff;
  color: #000;
  display: block;
  font-weight: bold;
}
.link-center {
  text-align: center;
  display: block;
  background: transparent;
  border: none;
}
.section-main-form {
  /*padding: 50% 0 0px;*/
  padding: 55px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}
.button-row {
  margin: 0 0 15px;
}
.error-message {
  margin: -20px 0 35px;
  font-size: 13px;
  line-height: 16px;
  color: #c70629;
}
